import {useContext} from 'react';
import {addPart, getParts, removePart, updatePart} from "../redux/slices/part";
import {createEntityContext} from "./EntityContext";

const {EntityContext: PartsContext, EntityProvider: PartsProvider} = createEntityContext({
    selectEntities: state => state.parts.parts,
    selectEntitiesError: state => state.parts.error,
    fetchEntitiesAction: getParts,
    fetchEntitiesPayload: false,
    updateActions: {update: updatePart, add: addPart, remove: removePart},
    contextName: 'parts',
    columns: "",
});

export default PartsProvider;

export const useParts = () => useContext(PartsContext);
