import {useContext} from 'react';
import {addUser, getUsers, removeUser, updateUser} from "../redux/slices/users";
import {createEntityContext} from "./EntityContext";

const {EntityContext: UsersContext, EntityProvider: UsersProvider} = createEntityContext({
    selectEntities: state => state.users.users,
    selectEntitiesError: state => state.users.error,
    fetchEntitiesAction: getUsers,
    updateActions: {update: updateUser, add: addUser, remove: removeUser},
    contextName: 'users',
    columns: "supervisorId,firstName,lastName,name,identifier,permissions,picture,avatar,status,location,email,modifiedDate,createdDate,active,department,locationId,locationDetails",
});

export default UsersProvider;

export const useUsers = () => useContext(UsersContext);
