import {createSlice, current} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    partsCollected: false,
    error: null,
    parts: [],
    partsSuppliers: [],
    partsSuppliersCollected: false,
    partModifiers: [],
    partClasses: [],
    partsForConsumer: [],
    partQuestions: [],
    partSymptoms: [],
    partProblemCodes: [],
    part: null
};

const slice = createSlice({
    name: 'part',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getPartsSuccess(state, action) {
            state.isLoading = false;
            state.partsCollected = true;
            state.parts = action.payload;
        },

        // GET SERVICES
        getPartSuppliersSuccess(state, action) {
            console.log('getPartSuppliersSuccess', {state, action})
            state.isLoading = false;
            state.partsSuppliersCollected = true;
            state.partsSuppliers = action.payload;
        },

        // GET SERVICE QUESTIONS
        getPartQuestionsSuccess(state, action) {
            state.isLoading = false;
            state.partQuestions = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getPartSymptomsSuccess(state, action) {
            state.isLoading = false;
            state.partSymptoms = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getPartModifiersSuccess(state, action) {
            state.isLoading = false;
            state.partModifiers = action.payload;
        },


        // GET SERVICE SYMPTOMS
        getPartClassesSuccess(state, action) {
            state.isLoading = false;
            state.partClasses = action.payload;
        },

        // GET SERVICE PROBLEM CODES
        getPartProblemCodesSuccess(state, action) {
            state.isLoading = false;
            state.partProblemCodes = action.payload;
        },

        // GET SERVICE
        getPartSuccess(state, action) {
            state.isLoading = false;
            state.part = action.payload;
        },

        updatePartSuccess(state, action) {
            const {_id, ...partData} = action.payload;
            state.parts = current(state.parts).map(part => (part._id === _id ? {
                ...part,
                ...partData
            } : part));
        },

        addPartSuccess(state, action) {
            state.parts = current(state.parts).concat([action.payload]);
        },

        removePartSuccess(state, action) {
            state.parts = current(state.parts).filter(part => part._id !== action.payload);
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addPart(part) {
    return async () => {
        try {
            dispatch(slice.actions.addPartSuccess(part));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function removePart(part) {
    return async () => {
        try {
            dispatch(slice.actions.removePartSuccess(part));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updatePart(part) {
    return async () => {
        try {
            dispatch(slice.actions.updatePartSuccess(part));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getParts(forConsumer, callback) {
    return async () => {
        console.log("getParts")
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/parts', {params: {forConsumer}});
            dispatch(slice.actions.getPartsSuccess(response.data.parts));
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartSuppliers() {
    return async () => {
        console.log("getParts")
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/parts/suppliers');
            console.log('getPartSuppliers', {response})
            dispatch(slice.actions.getPartSuppliersSuccess(response.data.partsSuppliers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartQuestions() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/questions');
            dispatch(slice.actions.getPartQuestionsSuccess(response.data.questions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartProblemCodes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/problem/codes');
            dispatch(slice.actions.getPartProblemCodesSuccess(response.data.codes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartSymptoms() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/symptoms');
            dispatch(slice.actions.getPartSymptomsSuccess(response.data.symptoms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartModifiers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/modifiers');

            dispatch(slice.actions.getPartModifiersSuccess(response.data.modifiers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPartClasses() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/part/vehicle/sizes');

            dispatch(slice.actions.getPartClassesSuccess(response.data.classes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPart(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/part`, {
                params: {identifier},
            });
            dispatch(slice.actions.getPartSuccess(response?.data?.part ? response.data.part[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setPart(part) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getPartSuccess(part));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
