import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    blobSasToken: null,
    contentSasToken: null,
    blobSasUrl: null,
    contentSasUrl: null,
};

const slice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getBlobSasTokenSuccess(state, action) {
            const {token, url} = action.payload;
            state.blobSasUrl = url;
            state.blobSasToken = token;
        },

        getContentSasTokenSuccess(state, action) {
            const {token, url} = action.payload;
            state.contentSasUrl = url;
            state.contentSasToken = token;
        },

    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBlobSasToken() {
    return async () => {
        try {
            const params = {
                container: "not needed",
                blobName: "not needed"
            }
            const response = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/sas/token`, {params})
            dispatch(slice.actions.getBlobSasTokenSuccess(response.data.token));
            return response.data.token
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getContentSasToken(container, blobName) {
    return async () => {
        try {
            const params = {
                container: "not needed",
                blobName: "not needed"
            }
            const response = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/content/sas/token`, {params})
            dispatch(slice.actions.getContentSasTokenSuccess(response.data.token));
            return response.data.token
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
