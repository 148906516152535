import PropTypes from "prop-types";
import {createContext, useCallback, useEffect, useState} from "react";
import {dispatch} from "../redux/store";
import useEnvironment from "../hooks/useEnvironment";
import {useSelector} from "react-redux";
import {get} from "lodash";
import {useAppData} from "./AppDataContext";


export function createEntityContext(
    {
        selectEntities,
        selectEntitiesError,
        fetchEntitiesAction,
        fetchEntitiesPayload,
        updateActions, // { update, add, remove }
        contextName,
        columns,
    }
) {
    const EntityContext = createContext({entities: []});

    const EntityProvider = ({children}) => {
        const [listenerSet, setListenerSet] = useState(false);
        const entities = useSelector(selectEntities);
        const entitiesError = useSelector(selectEntitiesError);
        const {activeOrganization} = useSelector(state => state.organization);
        const {connection, applyMethod, removeMethod, connected, isLoading} = useAppData();
        const {environmentReady} = useEnvironment();

        useEffect(() => {
            if (entitiesError) {
                console.error("Entity Provider Redux Error: ", entitiesError)
            }
        }, [entities])

        const messageReceived = useCallback((alert) => {
            console.log("EntityContext Message Received", alert);
            try {

                const {operationType, _id, updateDescription, fullDocument} = alert;
                const updatedFields = get(updateDescription, "updatedFields", {});
                const removedFields = get(updateDescription, "removedFields", {});
                switch (operationType) {
                    case "update":
                        console.log("EntityContext Update Message Received Call", updateActions.update);
                        dispatch(updateActions.update({_id, ...updatedFields}, removedFields));
                        break;
                    case "insert":
                        dispatch(updateActions.add(fullDocument));
                        break;
                    case "delete":
                        dispatch(updateActions.remove(_id));
                        break;
                    default:
                        console.log(`EntityProvider ${operationType} not found`)
                        break;
                }
            } catch (error) {
                console.error("EntityContext Message Received Error", error);
            }
        }, []);

        useEffect(() => {
            if (activeOrganization && messageReceived && !listenerSet && !isLoading && connected) {
                setListenerSet(true);
                console.log("EntityContext Apply Method", `${activeOrganization._id}-${contextName}`);
                applyMethod(`${activeOrganization._id}-${contextName}`, messageReceived);
            }
        }, [listenerSet, messageReceived, connection, applyMethod, removeMethod, connected, isLoading, activeOrganization]);

        useEffect(() => {
            if (environmentReady) {
                dispatch(fetchEntitiesAction(![null, undefined].includes(fetchEntitiesPayload) ? fetchEntitiesPayload : {
                    doloomaOnly: true,
                    columns,
                    active: true
                }));
            }
        }, [environmentReady]);

        return (
            <EntityContext.Provider value={{entities}}>
                {children}
            </EntityContext.Provider>
        );
    };

    EntityProvider.propTypes = {
        children: PropTypes.object,
    };

    return {EntityContext, EntityProvider};
}
