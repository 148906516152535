import {useContext} from 'react';
import {createEntityContext} from "./EntityContext";
import {addSettings, getSettings, removeSettings, updateSettings} from "../redux/slices/settings";

const {EntityContext: AppSettingsContext, EntityProvider: AppSettingsProvider} = createEntityContext({
    selectEntities: state => state.settings.settings,
    selectEntitiesError: state => state.settings.error,
    fetchEntitiesAction: getSettings,
    updateActions: {update: updateSettings, add: addSettings, remove: removeSettings},
    contextName: 'settings',
    columns: "",
});

export default AppSettingsProvider;

export const useAppSettings = () => useContext(AppSettingsContext);
