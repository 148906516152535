import React, {createContext, useContext} from 'react';
import useIndividualSignalR from '../hooks/useIndividualSignalR';

export const AppDataContext = createContext(null);

export const AppDataProvider = ({children}) => {
    // Initialize the connection once
    const [connection, applyMethod, removeMethod, connected, isLoading] = useIndividualSignalR('appdata');

    return (
        <AppDataContext.Provider value={{connection, applyMethod, removeMethod, connected, isLoading}}>
            {children}
        </AppDataContext.Provider>
    );
};

// Custom hook for easy access
export const useAppData = () => useContext(AppDataContext);
