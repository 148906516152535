import {createSlice, current} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import {TOW_TRUCK_MODEL} from "../../config";
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    servicesLoaded: false,
    error: null,
    services: [],
    serviceTypes: [],
    serviceCategories: [],
    serviceSubCategories: [],
    serviceModifiers: [],
    serviceClasses: [],
    servicesForConsumer: [],
    serviceQuestions: [],
    serviceSymptoms: [],
    serviceProblemCodes: [],
    service: null
};

const slice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START LOADING
        recollectServices(state) {
            state.servicesLoaded = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getServicesSuccess(state, action) {
            state.isLoading = false;
            state.servicesLoaded = true;
            state.services = action.payload;
        },

        // GET SERVICES
        getServiceTypesSuccess(state, action) {
            state.isLoading = false;
            state.serviceTypes = action.payload;
        },

        // GET SERVICE QUESTIONS
        getServiceQuestionsSuccess(state, action) {
            state.isLoading = false;
            state.serviceQuestions = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getServiceSymptomsSuccess(state, action) {
            state.isLoading = false;
            state.serviceSymptoms = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getServiceCategoriesSuccess(state, action) {
            state.isLoading = false;
            state.serviceCategories = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getServiceSubCategoriesSuccess(state, action) {
            state.isLoading = false;
            state.serviceSubCategories = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getServiceModifiersSuccess(state, action) {
            state.isLoading = false;
            state.serviceModifiers = action.payload;
        },


        // GET SERVICE SYMPTOMS
        getServiceClassesSuccess(state, action) {
            state.isLoading = false;
            state.serviceClasses = action.payload;
        },

        // GET SERVICE PROBLEM CODES
        getServiceProblemCodesSuccess(state, action) {
            state.isLoading = false;
            state.serviceProblemCodes = action.payload;
        },

        // GET SERVICE
        getServiceSuccess(state, action) {
            state.isLoading = false;
            state.service = action.payload;
        },

        updateServiceSuccess(state, action) {
            const {_id, ...serviceData} = action.payload;
            state.services = current(state.services).map(service => (service._id === _id ? {
                ...service,
                ...serviceData
            } : service));
        },

        addServiceSuccess(state, action) {
            state.services = current(state.services).concat([action.payload]);
        },

        removeServiceSuccess(state, action) {
            state.services = current(state.services).filter(service => service._id !== action.payload);
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getServices(forConsumer, callback) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services', {params: {forConsumer}});
            console.log('getServices', {response})
            dispatch(slice.actions.getServicesSuccess(response.data.services));
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addService(service) {
    return async () => {
        try {
            dispatch(slice.actions.addServiceSuccess(service));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function removeService(service) {
    return async () => {
        try {
            dispatch(slice.actions.removeServiceSuccess(service));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateService(service) {
    return async () => {
        try {
            dispatch(slice.actions.updateServiceSuccess(service));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceTypes(forConsumer, callback) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getServiceTypesSuccess(TOW_TRUCK_MODEL.TYPES));
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceQuestions() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/questions');
            dispatch(slice.actions.getServiceQuestionsSuccess(response.data.questions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceProblemCodes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/problem/codes');
            dispatch(slice.actions.getServiceProblemCodesSuccess(response.data.codes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceSymptoms() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/symptoms');
            dispatch(slice.actions.getServiceSymptomsSuccess(response.data.symptoms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceCategories() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/categories');
            dispatch(slice.actions.getServiceCategoriesSuccess(response.data.categories));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceSubCategories() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/sub/categories');
            dispatch(slice.actions.getServiceSubCategoriesSuccess(response.data.subCategories));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceModifiers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/modifiers');

            dispatch(slice.actions.getServiceModifiersSuccess(response.data.modifiers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceClasses() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/vehicle/sizes');

            dispatch(slice.actions.getServiceClassesSuccess(response.data.classes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getService(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/service`, {
                params: {identifier},
            });
            dispatch(slice.actions.getServiceSuccess(response?.data?.service ? response.data.service[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setService(service) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getServiceSuccess(service));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
