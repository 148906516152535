import {useContext} from 'react';
import {addHoo, getHoos, removeHoo, updateHoo} from "../redux/slices/hoo";
import {createEntityContext} from "./EntityContext";

const {EntityContext: HoosContext, EntityProvider: HoosProvider} = createEntityContext({
    selectEntities: state => state.hoos.hoos,
    selectEntitiesError: state => state.hoos.error,
    fetchEntitiesAction: getHoos,
    updateActions: {update: updateHoo, add: addHoo, remove: removeHoo},
    contextName: 'hours-of-operation',
    columns: "",
});

export default HoosProvider;

export const useHoos = () => useContext(HoosContext);
