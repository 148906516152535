import {useContext} from 'react';
import {addService, getServices, removeService, updateService} from "../redux/slices/service";
import {createEntityContext} from "./EntityContext";

const {EntityContext: ServicesContext, EntityProvider: ServicesProvider} = createEntityContext({
    selectEntities: state => state.services.services,
    selectEntitiesError: state => state.services.error,
    fetchEntitiesAction: getServices,
    fetchEntitiesPayload: false,
    updateActions: {update: updateService, add: addService, remove: removeService},
    contextName: 'services',
    columns: "",
});

export default ServicesProvider;

export const useServices = () => useContext(ServicesContext);
