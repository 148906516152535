import {createSlice, current} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    usersCollected: false,
    error: null,
    users: [],
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROLES
        getUsersSuccess(state, action) {
            state.isLoading = false;
            state.usersCollected = true;
            state.users = action.payload;
        },

        updateUserSuccess(state, action) {
            const {_id, ...userData} = action.payload;
            state.users = current(state.users).map(user => (user._id === _id ? {
                ...user,
                ...userData
            } : user));
        },

        addUserSuccess(state, action) {
            state.users = current(state.users).concat([action.payload]);
        },

        removeUserSuccess(state, action) {
            state.users = current(state.users).filter(user => user._id !== action.payload);
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers(params = {}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/accounts/users', {params});
            dispatch(slice.actions.getUsersSuccess(response.data.users));
        } catch (error) {
            console.error({error})
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateUser(user) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.updateUserSuccess(user));
        } catch (error) {
            console.error({error})
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addUser(user) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.addUserSuccess(user));
        } catch (error) {
            console.error({error})
            dispatch(slice.actions.hasError(error));
        }
    };
}


// ----------------------------------------------------------------------

export function removeUser(_id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.removeUserSuccess(_id));
        } catch (error) {
            console.error({error})
            dispatch(slice.actions.hasError(error));
        }
    };
}
