import {createSlice, current} from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import axios from "../../utils/axios";
import {
    addDays,
    eachDayOfInterval,
    endOfDay,
    endOfMonth,
    endOfWeek,
    format,
    startOfDay,
    startOfMonth,
    startOfWeek
} from "date-fns";
import {cloneDeep, debounce, find, get} from "lodash";
import {alpha} from "@mui/material/styles";

// ----------------------------------------------------------------------

const initialState = {
    scheduleFiltering: false,
    scheduleLoading: false,
    scheduleRefreshing: false,
    schedule: null,
    scheduleLoaded: false,
    scheduleData: [],
    scheduleDataLoaded: false,
    events: [],
    filteredEvents: [],
    eventsLoading: false,
    eventsLoaded: null,
    resources: [],
    filteredResources: [],
    resourcesLoading: false,
    resourcesLoaded: false,
    settings: null,
    settingsLoading: false,
    dateRange: new Date(),
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
    days: eachDayOfInterval({start: startOfMonth(new Date()), end: endOfMonth(new Date())}),
    roles: ['Dispatcher', 'Driver', 'Yard Staff', 'Mechanic'],
    gapCoverages: {},
    filters: {
        filter: "",
        role: "All",
        location: "All",
        shift: "All",
        view: "month",
        grid: "resource",
        by: "resource"
    },
    resourcesInView: {},
    dataCollectedFor: {}
};

const slice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.scheduleLoading = true;
            state.scheduleLoaded = false;

            state.resourcesLoading = true;
            state.resourcesLoaded = false;

            state.eventsLoading = true;
            state.eventsLoaded = false;

            state.scheduleDataLoaded = false

            state.gapCoverages = {};
        },

        startRefreshing(state, action) {
            if (action.payload?.fullReload === true) {
                state.dataCollectedFor = {}
                state.schedule = [];
                state.events = [];
                state.resources = [];
            }
            state.scheduleRefreshing = true;

            state.resourcesLoading = true;
            state.resourcesLoaded = false;

            state.eventsLoading = true;
            state.eventsLoaded = false;

            state.scheduleDataLoaded = false

            state.gapCoverages = {};
        },

        // HAS ERROR
        hasError(state, action) {
            state.scheduleLoading = false;
            state.error = action.payload;
        },

        updateItemSchedule(state, action) {
            const {schedule, users, activeOrganization, theme} = action.payload;
            const settings = state.settings ? current(state.settings) : {
                colors: {
                    Working: theme.palette.success.main,
                    Off: theme.palette.error.main,
                    Holiday: theme.palette.info.main,
                    Training: theme.palette.warning.main,
                    Float: theme.palette.primary.main,
                }
            };

            const newEvents = [];

            schedule?.forEach(schedule => {
                const user = users.find(user => user._id === schedule.employeeId);
                const {name, permissions, location} = user;
                const permission = find(permissions, {id: activeOrganization});

                schedule.schedule.forEach(scheduleEntry => {
                    const {date, shift, notes} = scheduleEntry;
                    scheduleEntry.assignments.forEach((assignment, index) => {
                        const {startTime, endTime, status} = assignment;
                        const eventStart = `${date}T${startTime || "00:00"}`;
                        let eventEnd = `${date}T${endTime || "23:59"}`;
                        if (endTime < startTime) {
                            const nextDay = addDays(new Date(date), 1).toISOString().split("T")[0];
                            eventEnd = `${nextDay}T${endTime || "23:59"}`;
                        }
                        newEvents.push({
                            title: `${status || shift}`,
                            start: eventStart,
                            end: eventEnd,
                            allDay: !startTime && !endTime,
                            backgroundColor: alpha(settings?.colors[status || shift] || theme.palette.primary.main, 0.75), // Yellow for missing shifts
                            borderColor: settings?.colors[`${status || shift}Dark`] || theme.palette.primary.dark,
                            textColor: "#fff",
                            resourceId: schedule.employeeId,
                            extendedProps: {
                                day: date,
                                notes,
                                shift,
                                item: {
                                    ...schedule,
                                    location: location._id,
                                    name,
                                    type: permission?.role
                                },
                                assignment,
                                employeeId: schedule.employeeId,
                                _id: schedule._id,
                                assignmentIndex: index
                            }
                        })
                    })
                })
            })

            state.events = newEvents;
            state.filteredEvents = filterEvents(newEvents, current(state.filters), current(state.resourcesInView));
        },

        loadScheduleSuccess(state, action) {
            const {schedule, view, dateRange, fullYear} = action.payload
            const dateRangeValue = fullYear ? format(dateRange, "yyyy") : format(dateRange, "yyyy-MM-dd");
            const dataCollected = get(current(state.dataCollectedFor), fullYear ? "year" : view, [])
            if (dataCollected.length > 0 && !dataCollected.includes(dateRangeValue)) {
                state.schedule = state.schedule.concat(schedule)
            } else {
                state.schedule = schedule;
            }
            if (fullYear) {
                state.dataCollectedFor = {
                    ...current(state.dataCollectedFor),
                    "year": [
                        ...(current(state.dataCollectedFor).year || []),
                        dateRangeValue
                    ]
                }
            } else {
                state.dataCollectedFor = {
                    ...current(state.dataCollectedFor),
                    [view]: [
                        ...(current(state.dataCollectedFor)[view] || []),
                        dateRangeValue
                    ]
                }
            }
            state.scheduleLoading = false;
            state.scheduleLoaded = true;
            state.scheduleRefreshing = false;
        },

        setSettings(state, action) {
            state.settings = action.payload;
            state.settingsLoaded = true;
        },

        loadEvents(state, action) {
            const newEvents = [];
            const users = action.payload.users
            const theme = action.payload.theme
            const activeOrganization = action.payload.activeOrganization
            const settings = state.settings ? current(state.settings) : {
                colors: {
                    Working: theme.palette.success.main,
                    Off: theme.palette.error.main,
                    Holiday: theme.palette.info.main,
                    Training: theme.palette.warning.main,
                    Float: theme.palette.primary.main,
                }
            };
            current(state.schedule)?.forEach(schedule => {
                const user = users.find(user => user._id === schedule.employeeId);
                const {name, permissions, location} = user;
                const permission = find(permissions, {id: activeOrganization});

                schedule.schedule.forEach(scheduleEntry => {
                    const {date, shift} = scheduleEntry;
                    scheduleEntry.assignments.forEach((assignment, index) => {
                        const {startTime, endTime, status} = assignment;
                        const eventStart = `${date}T${startTime || "00:00"}`;
                        let eventEnd = `${date}T${endTime || "23:59"}`;
                        if (endTime < startTime) {
                            const nextDay = addDays(new Date(date), 1).toISOString().split("T")[0];
                            eventEnd = `${nextDay}T${endTime || "23:59"}`;
                        }
                        newEvents.push({
                            title: `${status || shift}: ${name}`,
                            start: eventStart,
                            end: eventEnd,
                            allDay: !startTime && !endTime,
                            backgroundColor: alpha(settings?.colors[status || shift] || theme.palette.primary.main, 0.75), // Yellow for missing shifts
                            borderColor: settings?.colors[`${status || shift}Dark`] || theme.palette.primary.dark,
                            textColor: "#fff",
                            resourceId: schedule.employeeId,
                            extendedProps: {
                                day: date,
                                item: {
                                    ...schedule,
                                    location: location._id,
                                    name,
                                    type: permission?.role
                                },
                                assignment,
                                employeeId: schedule.employeeId,
                                _id: schedule._id,
                                assignmentIndex: index
                            }
                        })
                    })
                })
            })

            state.events = newEvents;
            state.filteredEvents = filterEvents(newEvents, current(state.filters), current(state.resourcesInView));
            state.eventsLoading = false;
            state.eventsLoaded = true;
        },

        loadResources(state, action) {
            const newResources = [];
            const users = action.payload.users;
            const activeOrganization = action.payload.activeOrganization;
            current(state.schedule)?.forEach(schedule => {
                const user = users.find(user => user._id === schedule.employeeId);
                const {_id, name, permissions} = user;
                const permission = find(permissions, {id: activeOrganization});
                newResources.push({
                    _id,
                    id: _id,
                    resourceId: _id,
                    title: name,
                    groupId: permission?.role,
                    extendedProps: {
                        user,
                        item: {
                            ...schedule,
                            name,
                            type: permission?.role
                        }
                    }
                })
            })

            state.resources = newResources;
            state.filteredResources = filterResources(newResources, current(state.events), current(state.filters));
            state.resourcesLoading = false;
            state.resourcesLoaded = true;
        },

        loadScheduleData(state, action) {
            const newScheduleData = []
            const users = action.payload.users;
            const activeOrganization = action.payload.activeOrganization;
            state.schedule?.forEach(schedule => {
                const user = users.find(user => user._id === schedule.employeeId);
                const {_id, avatar, name, permissions, locationDetails} = user;
                const permission = find(permissions, {id: activeOrganization});
                newScheduleData.push({
                    permissions,
                    employeeId: schedule.employeeId,
                    location: locationDetails,
                    name,
                    avatar,
                    schedule: [],
                    type: permission.role,
                    ...schedule
                });
            })
            state.scheduleData = newScheduleData;
            state.scheduleDataLoaded = true;
        },

        setGapCoverages(state, action) {
            const {gapCoverages, dateRange} = action.payload;

            state.gapCoverages[format(dateRange, "yyyy-MM")] = gapCoverages
        },

        setView(state, action) {
            state.filters.view = action.payload;
            let start, end;
            switch (action.payload) {
                case 'day':
                    start = startOfDay(state.dateRange);
                    end = endOfDay(state.dateRange);
                    break;
                case 'week':
                    start = startOfWeek(state.dateRange);
                    end = endOfWeek(state.dateRange);
                    break;
                case 'month':
                    start = startOfMonth(state.dateRange);
                    end = endOfMonth(state.dateRange);
                    break;
                default:
                    start = startOfMonth(state.dateRange);
                    end = endOfMonth(state.dateRange);
                    break;
            }
            state.start = start;
            state.end = end;
            state.days = eachDayOfInterval({start, end});
        },

        setFilter(state, action) {
            state.filters.filter = action.payload;
        },

        setLocation(state, action) {
            state.filters.location = action.payload;
        },

        setShift(state, action) {
            state.filters.shift = action.payload;
        },

        setRole(state, action) {
            state.filters.role = action.payload;
        },

        setGrid(state, action) {
            state.filters.grid = action.payload;
        },

        setDefaultLocation(state, action) {
            state.filters.location = action.payload;
        },

        setBy(state, action) {
            state.filters.by = action.payload;
        },

        setDateRange(state, action) {
            state.dateRange = action.payload;
        },

        startFiltering(state, action) {
            state.scheduleFiltering = true;
        },

        setFilteredEvents(state, action) {
            state.filteredEvents = action.payload;
            state.scheduleFiltering = false;
        },

        setFilteredFilteredResources(state, action) {
            state.filteredResources = action.payload;
        },

        setResourcesInView(state, action) {
            const {id, status} = action.payload;
            state.resourcesInView = {
                ...current(state.resourcesInView),
                [id]: status
            };
        },

        resetFilters(state, action) {
            state.filters = {
                filter: "",
                role: "All",
                location: "All",
                shift: "All",
                view: "month",
                grid: "resource",
                by: "resource"
            }
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    setGapCoverages,
    loadScheduleData,
    setSettings,
    loadEvents,
    loadResources,
    setDefaultLocation,
    setResourcesInView,
} = slice.actions;

// ----------------------------------------------------------------------

const filterEvents = (events, filters, resourcesInView) => {
    const {filter, role, location, shift, grid} = filters
    // .filter(event => filters.grid === "resource" || resourcesInView[event.resourceId])

    let updatedData = cloneDeep(events);

    if (shift.toLowerCase() !== 'all') {
        updatedData = updatedData?.filter(i => i.extendedProps?.item?.schedule.some(entry => entry.shift === shift));
    }

    if (role.toLowerCase() !== 'all') {
        updatedData = updatedData?.filter(i => i.extendedProps?.item?.type.toLowerCase() === role.toLowerCase());
    }

    if (location.toLowerCase() !== 'all') {
        updatedData = updatedData?.filter(i => i.extendedProps?.item?.location === location);
    }

    if (filter) {
        updatedData = updatedData?.filter(i => i.extendedProps?.item?.name?.toLowerCase().includes(filter.toLowerCase()));
    }

    if (grid !== "calendar") {
        // updatedData = updatedData?.filter(event => resourcesInView[event.resourceId]);
    }

    return updatedData;
};

// ----------------------------------------------------------------------

const filterResources = (resources, events, filters) => {
    const {filter, role, location} = filters

    const filteredEvents = filterEvents(events, filters, {})

    if (filteredEvents.length === 0) {
        return resources
    }

    let updatedData = cloneDeep(resources);

    if (role.toLowerCase() !== 'all') {
        updatedData = updatedData?.filter(i => i.extendedProps?.item?.type.toLowerCase() === role.toLowerCase());
    }

    if (location.toLowerCase() !== 'all') {
        updatedData = updatedData?.filter(i => i.extendedProps?.user?.location?._id === location);
    }

    if (filter) {
        updatedData = updatedData?.filter(i => i.extendedProps?.item?.name?.toLowerCase().includes(filter.toLowerCase()));
    }

    updatedData = updatedData?.filter(i => events.some(event => event.resourceId === i.id));

    return updatedData;
};

// ----------------------------------------------------------------------

// Create a debounced helper function that dispatches setFilter after 300ms
const debouncedDispatchSetFilter = debounce((dispatch, newFilter) => {
    dispatch(slice.actions.setFilter(newFilter));
}, 300);

// Thunk that calls the debounced function
export const setFilter = (newFilter) => (dispatch) => {
    debouncedDispatchSetFilter(dispatch, newFilter);
};

// ----------------------------------------------------------------------

// Create a debounced helper function that dispatches setFilter after 300ms
const debouncedDispatchSetRole = debounce((dispatch, newFilter) => {
    dispatch(slice.actions.setRole(newFilter));
}, 1);

// Thunk that calls the debounced function
export const setRole = (newFilter) => (dispatch) => {
    debouncedDispatchSetRole(dispatch, newFilter);
};

// ----------------------------------------------------------------------

// Create a debounced helper function that dispatches setFilter after 300ms
const debouncedDispatchSetLocation = debounce((dispatch, newLocation) => {
    dispatch(slice.actions.setLocation(newLocation));
}, 1);

// Thunk that calls the debounced function
export const setLocation = (newLocation) => (dispatch) => {
    debouncedDispatchSetLocation(dispatch, newLocation);
};

// ----------------------------------------------------------------------

export function loadScheduleSettings() {
    return async () => {
        const response = await axios.get('/api/schedules/settings')
        dispatch(slice.actions.setSettings(response.data.settings[0]));
    }
}

// ----------------------------------------------------------------------

// Create a debounced helper function that dispatches setFilter after 300ms
const debouncedDispatchSetShift = debounce((dispatch, newLocation) => {
    dispatch(slice.actions.setShift(newLocation));
}, 1);

// Thunk that calls the debounced function
export const setShift = (newLocation) => (dispatch) => {
    debouncedDispatchSetShift(dispatch, newLocation);
};

// ----------------------------------------------------------------------

// Create a debounced helper function that dispatches setFilter after 300ms
const debouncedDispatchResetFilters = debounce((dispatch) => {
    dispatch(slice.actions.resetFilters());
}, 1);

// Thunk that calls the debounced function
export const resetFilters = () => (dispatch) => {
    debouncedDispatchResetFilters(dispatch);
};

// ----------------------------------------------------------------------

// Create a debounced helper function that dispatches setFilter after 300ms
const debouncedDispatchSetView = debounce((dispatch, newView) => {
    dispatch(slice.actions.setView(newView));
}, 1);

// Thunk that calls the debounced function
export const setView = (newView) => (dispatch) => {
    debouncedDispatchSetView(dispatch, newView);
};

// ----------------------------------------------------------------------

// Create a debounced helper function that dispatches setFilter after 300ms
const debouncedDispatchSetGrid = debounce((dispatch, newGrid) => {
    dispatch(slice.actions.setGrid(newGrid));
}, 1);

// Thunk that calls the debounced function
export const setGrid = (newGrid) => (dispatch) => {
    debouncedDispatchSetGrid(dispatch, newGrid);
};

// ----------------------------------------------------------------------

// Create a debounced helper function that dispatches setFilter after 300ms
const debouncedDispatchSetBy = debounce((dispatch, newBy) => {
    dispatch(slice.actions.setBy(newBy));
}, 1);

// Thunk that calls the debounced function
export const setBy = (newBy) => (dispatch) => {
    debouncedDispatchSetBy(dispatch, newBy);
};

// ----------------------------------------------------------------------

// Create a debounced helper function that dispatches setFilter after 300ms
const debouncedDispatchSetDateRange = debounce((dispatch, view, dataCollectedFor, roles, fullYear, dateRange) => {
    // dispatch(slice.actions.startLoading());
    const dataCollected = get(dataCollectedFor, fullYear ? "year" : view, [])
    const dateRangeTest = fullYear ? format(dateRange, "yyyy") : format(dateRange, "yyyy-MM-dd");
    if (!dataCollected.includes(dateRangeTest)) {
        dispatch(slice.actions.startRefreshing());
        let startDate, endDate;

        switch (view) {
            case 'day':
                startDate = startOfDay(dateRange);
                endDate = endOfDay(dateRange);
                break;
            case 'week':
                startDate = startOfWeek(dateRange);
                endDate = endOfWeek(dateRange);
                break;
            case 'month':
                startDate = startOfMonth(dateRange);
                endDate = endOfMonth(dateRange);
                break;
            default:
                startDate = startOfMonth(dateRange);
                endDate = endOfMonth(dateRange);
                break;
        }

        axios.get("/api/schedules", {
            params: {
                roles: roles.join(','),
                startDate,
                endDate,
                fullYear
            }
        })
            .then(response => dispatch(slice.actions.loadScheduleSuccess({
                schedule: response.data.schedules,
                view,
                dateRange,
                fullYear
            })))
            .catch(error => dispatch(slice.actions.hasError(error)))

    }

    dispatch(slice.actions.setDateRange(dateRange));
}, 300);

// Thunk that calls the debounced function
export const setDateRange = ({view, dataCollectedFor, roles, fullYear, dateRange}) => (dispatch) => {
    dispatch(slice.actions.setDateRange(dateRange));
    debouncedDispatchSetDateRange(dispatch, view, dataCollectedFor, roles, fullYear, dateRange);
};

// ----------------------------------------------------------------------

export function refreshSchedule({dateRange, view, roles, fullYear = false}) {
    return async () => {
        dispatch(slice.actions.startRefreshing({fullReload: true}));
        let startDate, endDate;

        switch (view) {
            case 'day':
                startDate = startOfDay(dateRange);
                endDate = endOfDay(dateRange);
                break;
            case 'week':
                startDate = startOfWeek(dateRange);
                endDate = endOfWeek(dateRange);
                break;
            case 'month':
                startDate = startOfMonth(dateRange);
                endDate = endOfMonth(dateRange);
                break;
            default:
                startDate = startOfMonth(dateRange);
                endDate = endOfMonth(dateRange);
                break;
        }

        try {
            const response = await axios.get("/api/schedules", {
                params: {
                    roles: roles.join(','),
                    startDate,
                    endDate,
                    fullYear
                }
            })
            dispatch(slice.actions.loadScheduleSuccess({schedule: response.data.schedules, view, dateRange, fullYear}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function saveItemSchedule(
    {
        item,
        schedule,
        dateRange,
        view,
        roles,
        fullYear = false,
        users,
        activeOrganization,
        theme
    }
) {
    return async () => {
        try {
            let startDate, endDate;

            switch (view) {
                case 'day':
                    startDate = startOfDay(dateRange);
                    endDate = endOfDay(dateRange);
                    break;
                case 'week':
                    startDate = startOfWeek(dateRange);
                    endDate = endOfWeek(dateRange);
                    break;
                case 'month':
                    startDate = startOfMonth(dateRange);
                    endDate = endOfMonth(dateRange);
                    break;
                default:
                    startDate = startOfMonth(dateRange);
                    endDate = endOfMonth(dateRange);
                    break;
            }
            let response;
            if (item._id) {
                response = await axios.post('/api/schedules', {
                    _id: item._id,
                    employeeId: item.employeeId,
                    schedule
                }, {
                    params: {
                        roles: roles.join(','),
                        startDate,
                        endDate,
                        fullYear
                    }
                });
            } else {
                response = await axios.put('/api/schedules', {
                    employeeId: item.employeeId,
                    schedule
                }, {
                    params: {
                        roles: roles.join(','),
                        startDate,
                        endDate,
                        fullYear
                    }
                })
            }

            dispatch(slice.actions.loadScheduleSuccess({schedule: response.data.schedules, view, dateRange, fullYear}));
            dispatch(slice.actions.updateItemSchedule({
                schedule: response.data.schedules,
                users,
                activeOrganization,
                theme
            }))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function loadSchedule({dateRange, view, roles, fullYear = false}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        let startDate, endDate;

        switch (view) {
            case 'day':
                startDate = startOfDay(dateRange);
                endDate = endOfDay(dateRange);
                break;
            case 'week':
                startDate = startOfWeek(dateRange);
                endDate = endOfWeek(dateRange);
                break;
            case 'month':
                startDate = startOfMonth(dateRange);
                endDate = endOfMonth(dateRange);
                break;
            default:
                startDate = startOfMonth(dateRange);
                endDate = endOfMonth(dateRange);
                break;
        }

        try {
            const response = await axios.get("/api/schedules", {
                params: {
                    roles: roles.join(','),
                    startDate,
                    endDate,
                    fullYear
                }
            })
            dispatch(slice.actions.loadScheduleSuccess({schedule: response.data.schedules, view, dateRange, fullYear}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

// Define debounced function outside the thunk
const debouncedUpdateFilteredEvents = debounce((dispatch, events, filters, resourcesInView) => {
    dispatch(slice.actions.startFiltering)
    const filtered = filterEvents(events, filters, resourcesInView);
    dispatch(slice.actions.setFilteredEvents(filtered));
}, 300);

// Define debounced function outside the thunk
const debouncedUpdateFilteredResources = debounce((dispatch, events, resources, filters) => {
    // dispatch(slice.actions.startFiltering)
    const filtered = filterResources(resources, events, filters);
    dispatch(slice.actions.setFilteredFilteredResources(filtered));
}, 300);

// Thunk that calls the debounced function
export const filterSchedule = ({events, resources, filters, resourcesInView}) => (dispatch) => {
    debouncedUpdateFilteredEvents(dispatch, events, filters, resourcesInView);
    debouncedUpdateFilteredResources(dispatch, events, resources, filters);
};
