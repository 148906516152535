import {createSlice, current} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import {groupBy} from "lodash";
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    hoosCollected: false,
    error: null,
    hoos: [],
    shifts: [],
    hoo: null
};

const slice = createSlice({
    name: 'hoo',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // START LOADING
        recollectHoos(state) {
            state.hoosCollected = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROLES
        getHoosSuccess(state, action) {
            state.isLoading = false;
            state.hoosCollected = true;
            const allHoos = groupBy(action.payload, 'type');
            state.hoos = [...(allHoos['hoo'] || []), ...(allHoos['undefined'] || []), ...(allHoos[''] || [])];
            state.shifts = allHoos['shift'] || [];
        },

        updateHooSuccess(state, action) {
            const {_id, ...hooData} = action.payload;
            const allHoos = groupBy(current(state.hoos).map(hoo => (hoo._id === _id ? {
                ...hoo,
                ...hooData
            } : hoo)), 'type');

            state.hoos = [...(allHoos['hoo'] || []), ...(allHoos['undefined'] || []), ...(allHoos[''] || [])];
            state.shifts = allHoos['shift'] || [];
        },

        addHooSuccess(state, action) {
            const allHoos = groupBy(current(state.hoos).concat([action.payload]), 'type');

            state.hoos = [...(allHoos['hoo'] || []), ...(allHoos['undefined'] || []), ...(allHoos[''] || [])];
            state.shifts = allHoos['shift'] || [];
        },

        removeHooSuccess(state, action) {
            state.hoos = current(state.hoos).filter(hoo => hoo._id !== action.payload);
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function updateHoo(hoo) {
    return async () => {
        try {
            dispatch(slice.actions.updateHooSuccess(hoo));
        } catch (error) {
            console.error({error})
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function addHoo(hoo) {
    return async () => {
        try {
            dispatch(slice.actions.addHooSuccess(hoo));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function removeHoo(_id) {
    return async () => {
        try {
            dispatch(slice.actions.removeHooSuccess(_id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// ----------------------------------------------------------------------

export function getHoos(params) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/hoos', {params});
            dispatch(slice.actions.getHoosSuccess(response.data.hoos));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function recollectHoos() {
    return async () => {
        try {
            dispatch(slice.actions.recollectHoos());
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getHoo(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/hoos`, {
                params: {identifier},
            });
            dispatch(slice.actions.getHooSuccess(response?.data?.hoos ? response.data.hoos[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setHoo(hoo) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getHooSuccess(hoo));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
