// PayrollAutocomplete.js
import React, {useCallback, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {
    addDays,
    addMonths,
    addWeeks,
    endOfYear,
    format,
    getDate,
    getYear,
    isBefore,
    isSameDay,
    isWithinInterval,
    nextDay,
    parseISO,
    setDate,
    setHours,
    setMinutes,
    startOfToday,
    startOfYear
} from 'date-fns';
import {formatInTimeZone, fromZonedTime, toZonedTime} from 'date-fns-tz';
import {each, find, first, get} from "lodash";
import {dispatch, useSelector} from "../redux/store";
import {
    getPayrollPeriods,
    getPayrollSettings,
    setCurrentPayPeriod,
    setPayrollLast50years,
    setPayrollOptions
} from "../redux/slices/payroll";
import useLocales from "./useLocales";
import useEnvironment from "./useEnvironment";

export default function usePayrollAutocomplete({workWeek, year, getPeriodStatuses, onChange}) {

    const {translate} = useLocales();

    const {
        payrollPeriodsCollected,
        currentPayPeriod,
        payrollPeriods,
        payrollSettings,
        payrollSettingsCollected,
        payrollOptions,
        payrollOptionsCollected,
        payrollOptionsLast50Years,
        payrollOptionsLast50YearsCollected
    } = useSelector((state) => state.payroll);

    const {environmentReady} = useEnvironment();

    useEffect(() => {
        if (!payrollSettingsCollected && environmentReady) {
            dispatch(getPayrollSettings());
        }
    }, [payrollSettingsCollected, environmentReady]);

    const {
        type,
        dayOfWeek,
        firstPayDate,
        secondPayDate,
        payDate,
        timeOfDay = "23:59",
        timeZone = "America/New_York"
    } = payrollSettings?.payPeriod || {};

    // Convert dayOfWeek to a date-fns day index (0: Sunday, 1: Monday, ..., 6: Saturday)
    const dayIndex = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(dayOfWeek);

    // Parse the starting date or default to today
    const firstPay = firstPayDate ? parseISO(firstPayDate) : null;
    const secondPay = secondPayDate ? parseISO(secondPayDate) : null;
    const monthlyPay = payDate ? parseISO(payDate) : null;

    // Define the start and end of the specified year
    const yearStart = startOfYear(new Date((year || getYear(new Date())) - 1, 0, 1));
    const yearEnd = endOfYear(new Date(year || getYear(new Date()), 11, 31));
    const today = startOfToday();

    const adjustDateToTimeZone = useCallback((date) => {
        // Split the timeOfDay into hours and minutes
        const [hours, minutes] = timeOfDay.split(':').map(Number);

        // Convert the updated date to the specified time zone
        let zonedDate = toZonedTime(date, timeZone);
        // Update the hours and minutes of the date object and
        zonedDate = setHours(zonedDate, hours);
        zonedDate = setMinutes(zonedDate, minutes);

        // Format the zoned date in the specified time zone
        const formattedDate = formatInTimeZone(zonedDate, timeZone, 'yyyy-MM-dd HH:mm:ssXXX');

        // Convert back to UTC
        const finalUtcDate = fromZonedTime(formattedDate, timeZone);

        return finalUtcDate;
    }, [timeOfDay, timeZone])

    // Function to generate payroll periods
    const generatePayrollPeriods = useCallback(() => {
        const payrollPeriods = [];

        if (type === "weekly" || type === "bi-weekly") {
            let nextPayrollDate = nextDay(yearStart, dayIndex);
            while (isBefore(nextPayrollDate, today) && isBefore(nextPayrollDate, yearEnd)) {
                const endDate = addDays(nextPayrollDate, type === "weekly" ? 7 : 14);
                if (isWithinInterval(nextPayrollDate, {start: yearStart, end: yearEnd})) {
                    payrollPeriods.push({
                        start: adjustDateToTimeZone(nextPayrollDate),
                        end: adjustDateToTimeZone(endDate)
                    });
                }
                nextPayrollDate = addWeeks(nextPayrollDate, type === "weekly" ? 1 : 2);
            }
        } else if (type === "semi-monthly") {
            let currentMonth = yearStart;
            while (isBefore(currentMonth, today) && isBefore(currentMonth, yearEnd)) {
                let payDate1 = setDate(currentMonth, getDate(firstPay));
                let payDate2 = setDate(currentMonth, getDate(secondPay));
                if (isWithinInterval(payDate1, {start: yearStart, end: yearEnd})) {
                    payrollPeriods.push({
                        start: adjustDateToTimeZone(payDate1),
                        end: adjustDateToTimeZone(payDate2)
                    });
                }
                currentMonth = addMonths(currentMonth, 1);
            }
        } else if (type === "monthly") {
            let currentMonth = yearStart;
            while (isBefore(currentMonth, today) && isBefore(currentMonth, yearEnd)) {
                let payDate = setDate(currentMonth, getDate(monthlyPay));
                const endDate = addMonths(payDate, 1);
                if (isWithinInterval(payDate, {start: yearStart, end: yearEnd})) {
                    payrollPeriods.push({
                        start: adjustDateToTimeZone(payDate),
                        end: adjustDateToTimeZone(endDate)
                    });
                }
                currentMonth = addMonths(currentMonth, 1);
            }
        }

        // Format the payroll periods and sort them from current to oldest
        return payrollPeriods
            .sort((a, b) => b.start - a.start)
            .map((period, index) => ({
                start: period.start,
                end: period.end,
                workWeek: payrollPeriods.length - index,
                formattedPeriod: `${payrollPeriods.length - index} - ${format(period.start, 'yyyy-MM-dd hh:mm a')} to ${format(period.end, 'yyyy-MM-dd hh:mm a')}`
            }));
    }, [adjustDateToTimeZone, dayIndex, firstPay, monthlyPay, secondPay, today, type, yearEnd, yearStart]);

    const generateOlderPayPeriods = useCallback((olderYearStart, olderYearEnd) => {
        const payrollPeriods = [];

        if (type === "weekly" || type === "bi-weekly") {
            let nextPayrollDate = nextDay(olderYearStart, dayIndex);
            while (isBefore(nextPayrollDate, today) && isBefore(nextPayrollDate, olderYearEnd)) {
                const endDate = addDays(nextPayrollDate, type === "weekly" ? 6 : 13);
                if (isWithinInterval(nextPayrollDate, {start: olderYearStart, end: olderYearEnd})) {
                    payrollPeriods.push({
                        start: adjustDateToTimeZone(nextPayrollDate),
                        end: adjustDateToTimeZone(endDate)
                    });
                }
                nextPayrollDate = addWeeks(nextPayrollDate, type === "weekly" ? 1 : 2);
            }
        } else if (type === "semi-monthly") {
            let currentMonth = olderYearStart;
            while (isBefore(currentMonth, today) && isBefore(currentMonth, olderYearEnd)) {
                let payDate1 = setDate(currentMonth, getDate(firstPay));
                let payDate2 = setDate(currentMonth, getDate(secondPay));
                if (isWithinInterval(payDate1, {start: olderYearStart, end: olderYearEnd})) {
                    payrollPeriods.push({
                        start: adjustDateToTimeZone(payDate1),
                        end: adjustDateToTimeZone(payDate2)
                    });
                }
                currentMonth = addMonths(currentMonth, 1);
            }
        } else if (type === "monthly") {
            let currentMonth = olderYearStart;
            while (isBefore(currentMonth, today) && isBefore(currentMonth, olderYearEnd)) {
                let payDate = setDate(currentMonth, getDate(monthlyPay));
                const endDate = addMonths(payDate, 1);
                if (isWithinInterval(payDate, {start: olderYearStart, end: olderYearEnd})) {
                    payrollPeriods.push({
                        start: adjustDateToTimeZone(payDate),
                        end: adjustDateToTimeZone(endDate)
                    });
                }
                currentMonth = addMonths(currentMonth, 1);
            }
        }

        // Format the payroll periods and sort them from current to oldest
        return payrollPeriods
            .sort((a, b) => b.start - a.start)
            .map((period, index) => ({
                start: period.start,
                end: period.end,
                workWeek: payrollPeriods.length - index,
                formattedPeriod: `${payrollPeriods.length - index} - ${format(period.start, 'yyyy-MM-dd')} to ${format(period.end, 'yyyy-MM-dd')}`
            }));
    }, [adjustDateToTimeZone, dayIndex, firstPay, monthlyPay, secondPay, today, type]);

    useEffect(() => {
        if (!payrollOptionsCollected && payrollSettingsCollected) {
            const payPeriods = generatePayrollPeriods()
            dispatch(setPayrollOptions(payPeriods))
        }
    }, [payrollOptionsCollected, payrollSettingsCollected, generatePayrollPeriods]);

    useEffect(() => {
        if (!payrollOptionsLast50YearsCollected && payrollSettingsCollected) {
            dispatch(setPayrollLast50years(Array.from({length: 250}, (_, i) => i + 1).map(i => generateOlderPayPeriods(yearStart - i, yearEnd - i))))
        }
    }, [payrollOptionsLast50YearsCollected, payrollSettingsCollected, generateOlderPayPeriods, yearEnd, yearStart]);

    // Find the current pay period (closest future payroll date)
    const findCurrentPayPeriod = useCallback((workWeek) => {
        const now = new Date();
        if (workWeek) {
            return find(payrollOptions, {workWeek})
        }
        return payrollOptions.find(option => isBefore(now, option.end) || isSameDay(now, option.end));
    }, [payrollOptions]);

    const [value, setValue] = useState(currentPayPeriod?.formattedPeriod || '');

    useEffect(() => {
        if ((!currentPayPeriod || !currentPayPeriod?.formattedPeriod) && (first(payrollOptions))) {
            console.log("current pay option change")
            dispatch(setCurrentPayPeriod(first(payrollOptions)))
        }
    }, [currentPayPeriod, payrollOptions, findCurrentPayPeriod]);

    useEffect(() => {
        if (workWeek) {
            console.log("work week change")
            dispatch(setCurrentPayPeriod(find(payrollOptions, {workWeek})))
        }
    }, [workWeek, payrollOptions]);

    useEffect(() => {
        if (value === '' || currentPayPeriod?.formattedPeriod !== value) {
            setValue(currentPayPeriod?.formattedPeriod || '');
        }
    }, [currentPayPeriod, value]);

    useEffect(() => {
        if (!payrollPeriodsCollected && environmentReady) {
            dispatch(getPayrollPeriods());
        }
    }, [payrollPeriodsCollected, environmentReady]);

    const findPeriodByDate = (dateString) => {
        const date = new Date(dateString);
        let period = null;
        each(payrollOptions, (option) => {
            if (option.start <= date && date <= option.end) {
                period = option
            }
        })
        if (!period) {
            each(payrollOptionsLast50Years, (olderOption) => {
                each(olderOption, (option) => {
                    if (option.start <= date && date <= option.end) {
                        period = option
                    }
                })
            })
        }
        return period
    }

    const isPeriodClosedByDate = (data) => {
        const jobQueryDate = get(payrollSettings, "payPeriod.jobQueryDate", "createdDate")
        const dateString = get(data, jobQueryDate, new Date().toISOString())
        const period = findPeriodByDate(dateString);
        return Boolean(period && (period.posted || period.closed))
    }

    return {
        payrollPeriodAutocomplete: (
            <Autocomplete
                fullWidth
                value={value}
                onChange={(e, newValue) => {
                    setValue(newValue)
                    const newPayPeriod = find(payrollOptions, {formattedPeriod: newValue})
                    dispatch(setCurrentPayPeriod(newPayPeriod));
                    if (onChange) {
                        onChange(newPayPeriod)
                    }
                }}
                options={payrollOptions.map(option => option.formattedPeriod)}
                getOptionLabel={(option) => {
                    const isCurrent = first(payrollOptions)?.formattedPeriod === option;
                    return isCurrent ? `${option} (Current)` : option;
                }}
                renderInput={(params) => <TextField {...params}
                                                    label={translate("hooks.sink.usePayrollAutocomplete.selectPayrollPeriod")}
                                                    variant="outlined"/>}
            />
        ),
        thisPayrollPeriod: first(payrollOptions),
        value,
        payrollPeriods,
        findPeriodByDate,
        isPeriodClosedByDate,
        payrollOptions,
        payrollOptionsLast50Years,
        currentPayPeriod,
        payrollSettings,
    };
};

