import jwtDecode from 'jwt-decode';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

const setSession = (accessToken, idToken) => {
    const event = new CustomEvent('setSession', {
        detail: {
            accessToken,
            idToken
        }
    });
    // Step 2: Dispatch custom event
    window.dispatchEvent(event);
    if (accessToken && idToken) {
        // dispatch(setSession(accessToken))
        window.localStorage.setItem('accessToken', accessToken);
        window.localStorage.setItem('idToken', idToken);
        axios.defaults.headers.common['x-dolooma-authentication'] = accessToken;
        axios.defaults.headers.common['x-dolooma-authorization'] = idToken;

    } else {
        // dispatch(setSession(null))
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        delete axios.defaults.headers.common['x-dolooma-authentication'];
        delete axios.defaults.headers.common['x-dolooma-authorization'];
    }
};

const setOrganizationSession = (organization) => {
    if (organization) {
        window.localStorage.setItem('activeOrganization', organization);
        axios.defaults.headers.common['x-dolooma-organization'] = organization;
    } else {
        // localStorage.removeItem('activeOrganization');
        delete axios.defaults.headers.common['x-dolooma-organization'];
    }
};

const setWorkspaceSession = (workspace) => {
    if (workspace) {
        window.localStorage.setItem('activeWorkspace', workspace);
        axios.defaults.headers.common['x-dolooma-workspace'] = workspace;
    } else {
        // localStorage.removeItem('activeWorkspace');
        delete axios.defaults.headers.common['x-dolooma-workspace'];
    }
};

export {isValidToken, setSession, setOrganizationSession, setWorkspaceSession};
