import {useContext} from 'react';
import {addLocation, getLocations, removeLocation, updateLocation} from "../redux/slices/location";
import {createEntityContext} from "./EntityContext";

const {EntityContext: LocationsContext, EntityProvider: LocationsProvider} = createEntityContext({
    selectEntities: state => state.locations.locations,
    selectEntitiesError: state => state.locations.error,
    fetchEntitiesAction: getLocations,
    updateActions: {update: updateLocation, add: addLocation, remove: removeLocation},
    contextName: 'locations',
    columns: "",
});

export default LocationsProvider;

export const useLocations = () => useContext(LocationsContext);
