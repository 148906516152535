import axios from 'axios';
// config
import {HOST_API, HOST_API_KEY} from '../config';
import {setUnauthorizedWarning} from "../redux/slices/session";
import {dispatch} from "../redux/store";
import jwtDecode from "jwt-decode";
import {setSession} from "./jwt";
import {cloneDeep} from "lodash";
import {convertToISOUTC} from "./formatTime";
import {endOfDay, startOfDay} from "date-fns";

const MAX_REQUESTS_COUNT = 2;
const INTERVAL_MS = 10;
let CURRENT_REQUESTS = 0;

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
    baseURL: HOST_API,
    params: {
        code: HOST_API_KEY,
    },
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Suite-Identifier': process.env.REACT_APP_SUITE_IDENTIFIER,
        'Access-Control-Allow-Origin': '*',
    },
});


axiosInstance.interceptors.request.use((config) =>
        // Do something before request is sent


        new Promise((resolve) => {
            const newConfig = cloneDeep(config);
            if (newConfig.params.startDate) {
                newConfig.params.startDate = convertToISOUTC(startOfDay(newConfig.params.startDate).toISOString());
            }
            if (newConfig.params.endDate) {
                newConfig.params.endDate = convertToISOUTC(endOfDay(newConfig.params.endDate).toISOString());
            }
            if (CURRENT_REQUESTS >= MAX_REQUESTS_COUNT) {
                const interval = setInterval(() => {
                    if (CURRENT_REQUESTS < MAX_REQUESTS_COUNT) {
                        CURRENT_REQUESTS += 1;
                        clearInterval(interval);
                        resolve({...newConfig, headers: {...newConfig.headers}});
                    }
                }, INTERVAL_MS);
            } else {
                CURRENT_REQUESTS += 1;
                resolve({...newConfig, headers: {...newConfig.headers}});
            }
        }), (error) => {
        // Do something with request error
        console.error({error, toJSON: error.toJSON()});
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        CURRENT_REQUESTS -= 1;

        const claims = jwtDecode(window.localStorage.getItem('accessToken'));
        const now = Math.floor(new Date().getTime() / 1000);
        const timeLeft = claims.exp - now;

        if (timeLeft <= 599) {
            axiosInstance.auth0Client.getTokenSilently()
                .then(accessToken => {
                    axiosInstance.auth0Client.getIdTokenClaims()
                        .then(idToken => {
                            setSession(accessToken, idToken.__raw);
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error));
        }
        return response;
    },
    (error) => {
        CURRENT_REQUESTS -= 1;
        console.error('Parse Axios Error', error)
        const {response: {status = 200, headers = null, config = null, data: {message = ''}}} = error || {};
        if (status === 401 && ['User is not authenticated.'].includes(message)) {
            window.location.reload();
        }
        if (status === 401 && ['Unauthorized.'].includes(message)) {
            dispatch(setUnauthorizedWarning(true))
        }
        if (headers) {
            console.error({apiVersion: headers.get('dooloma-api-version')})
        }
        console.error({error, toJSON: error.toJSON()});
        const {method, url = 'unknown'} = config || {}
        const okSupportRequest = (url.includes('api/support/request') && !['put'].includes(method)) || !url.includes('api/support/request')

        if (process.env.REACT_APP_ENV !== 'local' && status === 500 && okSupportRequest) {
            axiosInstance.put(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/support/request`, {
                foundInVersion: process.env.REACT_APP_VERSION,
                name: 'API Issue captured in app',
                description: `Path: ${url}

                    API Version: ${headers.get('dooloma-api-version') || 'Unknown'}
                    Error: ${message.toString()}
                    Page: ${window.location.href}
                    Full Error: ${JSON.stringify({
                    message,
                    url,
                })}`,
                type: "💥 Bug",
                prioritize: 'high'
            }, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            })
                .then((response) => {

                })
                .catch((error) => {
                    console.error(error)
                })
        }
        return Promise.reject((error.response && error.response?.data) || 'Something went wrong');
    },
);


export default axiosInstance;
