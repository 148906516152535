import PropTypes from 'prop-types';
import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';
import useEnvironment from "../hooks/useEnvironment";
import {PATH_REGISTRATION} from "../routes/paths";
import {useSelector} from "../redux/store";
import {setSession} from "../utils/jwt";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({children}) {
    const {isAuthenticated, isInitialized, auth0Client} = useAuth();
    const {navLoaded} = useSelector((state) => state.navigation);
    const session = useSelector((state) => state.session);
    const {environmentReady} = useEnvironment();
    const {pathname} = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);

    const {timeLeft, accessToken} = session;

    if (isAuthenticated && timeLeft <= 599) {
        auth0Client.getTokenSilently()
            .then(accessToken => {
                auth0Client.getIdTokenClaims()
                    .then(idToken => {
                        setSession(accessToken, idToken.__raw);
                    })
                    .catch((error) => console.error(error));
            })
            .catch((error) => console.error(error));
    }

    if (!accessToken && isInitialized) {
        return <Navigate to={'/'}/>;
    }

    if ((!isInitialized || !navLoaded) && pathname !== PATH_REGISTRATION.organization.root) {
        return <LoadingScreen/>;
    }

    if (environmentReady && !isAuthenticated && pathname !== PATH_REGISTRATION.organization.root) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Navigate to={'/'}/>;
    }

    if (!environmentReady && pathname !== PATH_REGISTRATION.organization.root) {
        console.log('Loading Screen 2')
        return <LoadingScreen/>;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation}/>;
    }

    const loginUrl = window.localStorage.getItem('loginUrl');
    if (window.localStorage.getItem('loginUrl') === "/") {
        window.localStorage.setItem('loginUrlRemovedBy', 'auth guard is a slash');
        window.localStorage.removeItem('loginUrl');
    }
    if (pathname === loginUrl) {
        window.localStorage.removeItem('loginUrl');
    }
    return <>{children}</>;
}
