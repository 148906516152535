import {useContext} from 'react';
import {addVehicle, getVehicles, removeVehicle, updateVehicle} from "../redux/slices/vehicle";
import {createEntityContext} from "./EntityContext";

const {EntityContext: VehiclesContext, EntityProvider: VehiclesProvider} = createEntityContext({
    selectEntities: state => state.vehicles.vehicles,
    selectEntitiesError: state => state.vehicles.error,
    fetchEntitiesAction: getVehicles,
    updateActions: {update: updateVehicle, add: addVehicle, remove: removeVehicle},
    contextName: 'vehicles',
    columns: "",
});

export default VehiclesProvider;

export const useVehicles = () => useContext(VehiclesContext);
